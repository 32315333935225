/**
 * @fileOverview NotFound component
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './ProductList.module.scss'

const ProductList = ({ products }) => (
  <div className={styles.root}>
    {products.map(product => (
      <Link to={`/product/${product.id}`} key={product.id} className={styles.product}>
        <span className={styles.detail}>
          <span className={styles.line}>
            <span className={styles.name}>{product.productName}</span>
            <span className={styles.field}>
              (<span className={styles.fieldName}>Catalog#:</span>C-{product.catalogId})
            </span>
            <span className={styles.field}>
              (<span className={styles.fieldName}>Target:</span>{product.target})
            </span>
            <span className={styles.field}>
              (<span className={styles.fieldName}>CAS#:</span>{product.cas})
            </span>
          </span>
          <span className={styles.summary}>
            <span className={styles.descriptionSummary}>{product.descriptionSummary}</span>
          </span>
        </span>
      </Link>
      )
    )}
  </div>
)

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    productName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    catalogId: PropTypes.number.isRequired,
    synonym: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    descriptionSummary: PropTypes.string.isRequired,
  })).isRequired,
}

export default ProductList
