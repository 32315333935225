import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import style from './Prices.module.scss'

const isChemgoodnetSite = /chemgoodnet/.test(window.location.hostname)

function generatePriceText(item) {
  if (item.askPrice) return 'Get Quotes'
  if (item.price === 0) return 'N/A'
  return isChemgoodnetSite ? `¥${item.price * 10}` : `$${item.price}`
}

const Prices = ({ prices, productName }) => (
  <div className={style.root}>
    <h2 className={style.title}>
      Prices
      <Link to={`/contact/${productName}`} className={style.request}>Request</Link>

    </h2>
    <div className={style.content}>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Sizes</th>
            <th>Prices(USD)</th>
          </tr>
        </thead>
        <tbody>
          {prices.map(item => (
            <tr key={item.id}>
              <td>{item.size}</td>
              <td>{generatePriceText(item)}</td>
            </tr>
            ))
          }
          <tr>
            <td>More Sizes</td>
            <td>Get Quotes</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

Prices.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    size: PropTypes.string.isRequired,
    askPrice: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
  })).isRequired,
  productName: PropTypes.string.isRequired,
}

export default Prices
