import React from 'react'
import { Link } from 'react-router-dom'

import s from './index.module.scss'

const baseCharCode = 65

function renderInitials() {
  const arr = []
  let letter
  for (let i = 0; i < 26; i += 1) {
    letter = String.fromCharCode(baseCharCode + i)
    arr.push(<Link to={`/products/${letter}`} key={letter} className={s.initial}>{letter}</Link>)
  }
  return arr
}

const Search = () => (
  <div className={s.root}>
    <div className={s.title}>Search By Initial</div>
    <div className={s.initials}>
      {renderInitials()}
    </div>
  </div>
)

export default Search
