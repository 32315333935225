import React from 'react'

import SearchBox from '../../core/components/SearchBox'

import style from './index.module.scss'

import * as service from '../../services/page'
var PO_PAGE_ID = 24
var CARD_PAGE_ID = 25

class Service extends React.Component {
  state = {
    poInfo: `
        Email PO document to <a href="mailto:sales@chemgood.com">sales@chemgood.com</a>.

    `,
    cardInfo: `
        Contact <a href="mailto:sales@chemgood.com">sales@chemgood.com</a> for instruction.
    `,
  }
  componentDidMount() {
    service.fetchPage(PO_PAGE_ID)
      .then(data => this.setState({
        poInfo: data.data[0].text,
      }))

    service.fetchPage(CARD_PAGE_ID)
      .then(data => this.setState({
        cardInfo: data.data[0].text,
      }))
  }
  render() {
    return (

      <div className={style.root}>
        <div className={style.searchContainer}>
          <SearchBox />
        </div>

        <div className={style.section}>
          <div className={style.title}>Order by PO#:</div>
          <div className={style.content} dangerouslySetInnerHTML={{ __html: this.state.poInfo }}>
          </div>
        </div>

        <div className={style.section}>
          <div className={style.title}>Order by Credit Card:</div>
          <div className={style.content} dangerouslySetInnerHTML={{ __html: this.state.cardInfo }}>
          </div>
        </div>
      </div>
    )
  }
}


export default Service
