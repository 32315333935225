import { get, post } from '../utils/api'


// 翻页从1开始
export function fetchProducts(params) {
  return get('/api/listProducts', {
    params,
  })
}

export function saveProduct(product, productDetail, prices) {
  const api = product.id ? '/api/updateProduct' : '/api/addProduct'
  const fullProductDO = {
    productDO: product,
    productDetailDO: productDetail,
    priceDOs: prices,
  }
  return post(api, fullProductDO)
}

export function fetchProduct(id) {
  return get('/api/queryProductDetailById', { params: { id } })
}

export function validateCatalogId(id) {
  return get('/api/isCatalogIdInUse', {
    params: {
      catalogId: id,
    },
  })
}

export function fetchTargets() {
  return get('/api/queryAllTarget')
}
