/**
 * @fileOverview User Module
 * This module is a core module which is responsible for all
 * user related actions, handlers
 */

import * as userService from '../../services/user'

// ------------------------------------
// Constants
// ------------------------------------
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const USER_LOGOUT = 'USER_LOGOUT'
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING'
export const FETCH_USER_COMPLETED = 'FETCH_USER_COMPLETED'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

// ------------------------------------
// Actions
// ------------------------------------
export function login({ email, password }) {
  return dispatch =>
    userService.login({ email, password })
      .then(userData => dispatch({
        type: USER_LOGIN,
        payload: userData,
      }))
      .catch(err => dispatch({
        type: USER_LOGIN_ERROR,
        payload: err,
      }))
}

export function logout() {
  return dispatch =>
    userService.logout().then(() => dispatch({
      type: USER_LOGOUT,
    }))
}

export function getCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_PENDING,
    })
    userService.getCurrentUser()
      .then(userData => dispatch({
        type: FETCH_USER_COMPLETED,
        payload: userData,
      }))
      .catch(err => dispatch({
        type: FETCH_USER_ERROR,
        payload: err,
      }))
  }
}

export const actions = {
  login,
  logout,
  getCurrentUser,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USER_LOGIN]: (state, action) => ({
    ...state,
    isLoggedIn: true,
    data: {
      email: action.payload,
    },
  }),
  [USER_LOGOUT]: state => ({
    ...state,
    isLoggedIn: false,
    data: {},
  }),
  [FETCH_USER_PENDING]: state => ({
    ...state,
    isFetchingUser: true,
  }),
  [FETCH_USER_COMPLETED]: (state, action) => {
    const data = action.payload
    return {
      isFetchingUser: false,
      isLoggedIn: !!data,
      data: {
        email: data,
      },
    }
  },
  [FETCH_USER_ERROR]: () => ({
    isFetchingUser: false,
    isLoggedIn: false,
    data: {},
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetchingUser: true,
  isLoggedIn: false,
  data: {},
}
export default function userReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
