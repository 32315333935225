import React, { Component } from 'react'

import * as pageService from '../../../../services/page'
import style from './About.module.scss'

const IMG_ID = 16
const INTRO_ID = 18

const INITIAL_STATE = {
  img: 'https://chemgood-static.s3-us-west-1.amazonaws.com/44b867c2-34db-44c6-9fc7-a47bec2f893c.jpg',
  text: 'Chemgood is dedicated to providing high quality small molecule anticancer agents for laboratory research at competitive prices. Chemgood has an outstanding team with brilliant chemists and pharmacologists, and a well-equipped lab located here in the USA. Chemgood possesses a tremendous catalog of synthesized anticancer agents and also offers custom synthesis in a cost and time-efficient manner. Welcome to collaborate with Chemgood.',
}

class About extends Component {

  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
  }

  componentDidMount() {
    pageService.fetchPage(IMG_ID)
      .then((data) => {
        const page = data.data[0]
        if (page && page.text) {
          this.setState({
            img: page.text,
          })
        }
      })
    pageService.fetchPage(INTRO_ID)
      .then((data) => {
        const page = data.data[0]
        if (page && page.text) {
          this.setState({
            intro: page.text,
          })
        }
      })
  }

  render() {
    const { img, intro } = this.state

    return (
      <div className={style.root}>
        <div className={style.about}>
          <div className={style.aboutContent}>
            <h2 className={style.aboutTitle}>About <span>Chemgood</span></h2>
            <p className={style.aboutDetail} dangerouslySetInnerHTML={{ __html: intro }} />
            <p className={style.warning}>All products are for Lab Research ONLY.</p>
          </div>
          <div className={style.aboutImgContainer}>
            <img src={img.trim()} alt="About" className={style.aboutImg} />
          </div>
        </div>
      </div>
    )
  }
}

export default About
