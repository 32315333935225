import * as productService from '../../services/product'

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE'
export const HIDE_FETCH_PRODUCTS_FAILURE = 'HIDE_FETCH_PRODUCTS_FAILURE'

// ------------------------------------
// Actions
// ------------------------------------
export function fetchProducts(toPage, perPageSize, showDeleted) {
  return (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS })
    productService.fetchProducts(toPage, perPageSize, showDeleted)
      .then(data => dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: {
          products: data.data,
          toPage: data.toPage,
          perPageSize: data.perPageSize,
          totalItem: data.totalItem,
          returnCount: data.returnCount,
        },
      }))
      .catch(err => dispatch({
        type: FETCH_PRODUCTS_FAILURE,
        payload: err,
      }))
  }
}

export function hideFetchProductsFailure() {
  return {
    type: HIDE_FETCH_PRODUCTS_FAILURE,
  }
}


const INITIAL_STATE = {
  products: [],
  perPageSize: 10,
  totalItem: 0,
  returnCount: 0,
  isErrorShow: false,
  isError: false,
  errorText: '',
  isFetching: false,
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FETCH_PRODUCTS]: state => ({
    ...state,
    isFetching: true,
  }),
  [FETCH_PRODUCTS_SUCCESS]: (state, action) => Object.assign({}, state, {
    ...action.payload,
    isFetching: false,
  }),
  [FETCH_PRODUCTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    isErrorShow: true,
    errorText: action.payload,
  }),
  [HIDE_FETCH_PRODUCTS_FAILURE]: state => ({
    ...state,
    isErrorShow: false,
  }),
}

export default function productListReducer(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
