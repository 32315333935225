/**
 * @fileOverview HomeView component
 */
import React, { Component } from 'react'

import * as productService from '../../../../services/product'
import SearchBox from '../../../../core/components/SearchBox'
import ProductList from '../../../../core/components/ProductList'
import About from './About'

import styles from './HomeView.module.scss'

const INITIAL_STATE = {
  products: [],
  showMore: true,
}

class HomeView extends Component {

  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
    this.params = {
      toPage: 1,
      perPageSize: 20,
      orderBy: 'featured_update_time',
      orderType: 'desc',
      featured: true,
    }
  }

  componentDidMount() {
    this.search({})
  }

  search = () => {
    productService.fetchProducts(this.params).then((data) => {
      this.setState({
        products: data.data,
      })
    }, (errorText) => {
      this.setState({
        errorText,
      })
    })
  }

  searchAll = () => {
    this.setState({
      showMore: false,
    })
    this.params.perPageSize = 50
    this.search()
  }

  render() {
    const { products, showMore } = this.state

    return (
      <div>
        <div className={styles.searchBoxContainer}>
          <SearchBox />
        </div>
        <About />
        <div className={styles.productListContainer}>
          <div className={styles.productListTitle}>Featured <span>Products</span> </div>
          <ProductList products={products} />
          {showMore ?
            <button
              className={`${styles.moreProducts} btn-brand`}
              onClick={this.searchAll}
            >View More</button>
            : null
          }
        </div>

      </div>
    )
  }
}

export default HomeView
