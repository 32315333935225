import React from 'react'
import PropTypes from 'prop-types'

import s from './TechInfo.module.scss'

const TechInfo = ({ productDO, productDetailDO }) => (
  <div className={s.root}>
    <h2 className={s.title}>Technical Data</h2>

    <div className={s.line}>
      <a
        href={productDetailDO.msds}
        download
        className={productDetailDO.msds ? s.download : s.downloadDisabled}
      >Download MSDS</a>
      <a
        href={productDetailDO.coa}
        download
        className={productDetailDO.coa ? s.download : s.downloadDisabled}
      >Download CoA</a>
    </div>


    <div className={s.line}>
      Product Name: <span>{productDO.productName}</span>
    </div>
    <div className={s.line}>
      Chemical Formula: <span>{productDetailDO.formula}</span>
    </div>
    {productDetailDO.molecularWeight ?
      <div className={s.line}>
        Molecular Weight: <span>{productDetailDO.molecularWeight.toFixed(2)}</span>
      </div>
      : null
    }
    <div className={s.line}>
      CAS#: <span>{productDO.cas}</span>
    </div>
    <div className={s.line}>
      Appearance: <span>{productDetailDO.appearance}</span>
    </div>
    <div className={s.line}>
      Purity: <span>{productDetailDO.purity}</span>
    </div>
    <div className={s.line}>
      Solubility: <span>{productDetailDO.solubility}</span>
    </div>
    <div className={s.line}>
      Storage: <span>{productDetailDO.storageConditions}</span>
    </div>
  </div>
)

TechInfo.propTypes = {
  productDO: PropTypes.shape({

  }).isRequired,
  productDetailDO: PropTypes.shape({

  }).isRequired,
}

export default TechInfo
