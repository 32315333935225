import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
// import injectTapEventPlugin from 'react-tap-event-plugin'

import './app/styles/main.scss'
import App from './app/App'

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin()


ReactDOM.render(<App />, document.getElementById('root'))

