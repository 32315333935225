import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import URI from 'urijs'

import * as productService from '../../../../services/product'

import ProductsPagination from './ProductsPagination'
import SearchBox from '../../../../core/components/SearchBox'
import SearchByInitial from '../../../../core/components/SearchByInitial'
import SearchByTarget from '../../../../core/components/SearchByTarget'
import ProductList from '../../../../core/components/ProductList'


import styles from './index.module.scss'


const INITIAL_STATE = {
  totalItem: 0,
  products: [],
  errorText: '',
}

class ProductListView extends Component {

  constructor(props) {
    super(props)
    console.log(props)
    this.state = INITIAL_STATE
  }

  componentDidMount() {
    this.search(this.props.match.params.keyword)
  }

  componentWillReceiveProps(nextProps) {
    this.search(nextProps.match.params.keyword)
  }

  handlePaginationChange = (toPage, perPageSize) => {
    const keyword = this.props.match.params.keyword || ''
    this.props.history.push(`/products/${keyword}?toPage=${toPage}&perPageSize=${perPageSize}`)
    // this.props.navigate(`/products/${keyword}?toPage=${toPage}&perPageSize=${perPageSize}`)
  }

  search = (keyword) => {
    const params = {}
    if (keyword) {
      if (keyword.length === 1) {
        params.initial = keyword
      } else {
        params.index = keyword
      }
    }
    const { toPage, perPageSize } = URI.parseQuery(window.location.search)
    params.toPage = toPage || 1
    params.perPageSize = perPageSize || 100
    params.orderBy = 'product_name'
    params.orderType = 'asc'
    this.fetchProducts(params)
  }

  fetchProducts(params) {
    productService.fetchProducts(params)
      .then((data) => {
        this.setState({
          products: data.data,
          totalItem: data.totalItem,
        })
      }, (errorText) => {
        this.setState({
          errorText,
        })
      })
  }

  handleSearch = (params) => {
    const keyword = params.keyword || ''
    this.props.navigate(`/products/${keyword}`)
  }

  render() {
    const { products, totalItem } = this.state
    let { toPage, perPageSize } = URI.parseQuery(window.location.search)
    const { keyword } = this.props.match.params
    toPage = +toPage || 1
    perPageSize = +perPageSize || 100


    return (
      <div className={styles.root}>
        <div className={styles.searchBoxContainer}>
          <SearchBox onSearch={this.handleSearch} keyword={keyword} />
        </div>
        <div className={styles.main}>
          <div className={styles.productListContainer}>

            <div className={styles.title}>Products</div>

            <div className="pb20">
              <ProductsPagination
                handlePaginationChange={this.handlePaginationChange}
                totalItem={totalItem}
                perPageSize={perPageSize}
                toPage={toPage}
              />
            </div>

            <ProductList products={products} />

            <div className="pt20 pb20">
              <ProductsPagination
                handlePaginationChange={this.handlePaginationChange}
                totalItem={totalItem}
                perPageSize={perPageSize}
                toPage={toPage}
              />
            </div>

          </div>

          <div className={styles.sidebar}>
            <SearchByInitial />
            <SearchByTarget />
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  navigate: url => dispatch(push(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductListView)
