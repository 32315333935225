/**
 * @fileOverview NotFound component
 */

import React from 'react'
import styles from './NotFound.module.scss'

const NotFound = () => (
  <div className={styles.root}>
    <h1>
      404 Not Found
    </h1>
  </div>
)

export default NotFound
