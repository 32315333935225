import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import user from '../core/modules/user'
import productList from '../core/modules/productList'
import order from '../core/modules/order'

const rootReducer = combineReducers({
  // react router
  router: routerReducer,

  // core modules
  user,

  // scenes modules
  productList,

  order,
})

export default rootReducer
