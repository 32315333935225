import React from 'react'
import { NavLink } from 'react-router-dom'

import Brand from '../Brand'
import style from './Header.module.scss'

import * as service from '../../../services/page'
const INFO_PAGE_ID = 28


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      info: `
            Phone# (Toll-free): 1-855-444-9966.
      `
    }
  }
  componentDidMount() {
    service.fetchPage(INFO_PAGE_ID)
      .then(data => this.setState({
        info: data.data[0].text,
      }))
  }

  render() {
    return (
      <div>
        <div className={style.brand}>
          <Brand />
        </div>
        <div className={style.nav}>
          <div className={style.navContent}>
            <ul className={style.navList}>
              <li className={style.navItem}>
                <NavLink to="/" exact className={style.navLink} activeClassName={style.navLinkActive}>Home</NavLink>
              </li>
              <li className={style.navItem}>
                <NavLink to="/products" exact className={style.navLink} activeClassName={style.navLinkActive}>Products</NavLink>
              </li>
              <li className={style.navItem}>
                <NavLink to="/service" exact className={style.navLink} activeClassName={style.navLinkActive}>Order</NavLink>
              </li>
              <li className={style.navItem}>
                <NavLink to="/contact" exact className={style.navLink} activeClassName={style.navLinkActive}>Contact</NavLink>
              </li>
              <li className={style.contact}>
                <span dangerouslySetInnerHTML={{ __html: this.state.info }}></span>
              </li>

            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
