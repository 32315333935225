/**
 * @fileOverview HeaderContainer
 *
 * This is a container component. Notice it does not contain any JSX,
 * nor does it import React. This component is **only** responsible for
 * wiring in the actions and state necessary to render a presentational
 * component
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Import component
import Header from '../components/Header'

// ------------------------------------
// React Redux mapToProps
// ------------------------------------

// Mapping state to component
const mapStateToProps = () => ({
})

// Object of action creators (can also be function that returns object).
// Keys will be passed as props to presentational components.
const mapDispatchToProps = {
}

// connect
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
