import React from 'react'
import PropTypes from 'prop-types'

import s from './index.module.scss'

const Captcha = ({ onClick, code }) => (
  <button
    className={s.captcha}
    onClick={onClick}
  >{code}</button>
)

Captcha.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Captcha
