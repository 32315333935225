import {
  get,
} from '../utils/api'

export function fetchPage(id) {
  return get('/api/queryStaticPage', {
    params: { id },
  })
}

export function foo() {}
