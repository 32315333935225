import {
  get,
} from '../utils/api'

export function save(order) {
  return get('/api/addOrder', {
    params: order,
  })
}

export function foo() {}
