import * as orderService from '../../services/order'

export const SAVE_ORDER = 'SAVE_ORDER'
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS'
export const SAVE_ORDER_FAILURE = 'SAVE_ORDER_FAILURE'

export const HIDE_ORDER_ERROR = 'HIDE_ORDER_ERROR'
export const HIDE_ORDER_SUCCESS = 'HIDE_ORDER_SUCCESS'

export const UPDATE_ORDER_FIELD = 'UPDATE_ORDER_FIELD'
export const RESET_ORDER_FIELDS = 'RESET_ORDER_FIELDS'

export const UPDATE_IS_VALID = 'UPDATE_IS_VALID'
export const HIDE_IS_VALID = 'HIDE_IS_VALID'

export function saveOrder() {
  return (dispatch, getState) => {
    const order = getState().order.order
    if (!order.productName) {
      dispatch({
        type: UPDATE_IS_VALID,
        isValid: false,
        validMsg: 'product name is required',
      })
      return
    }
    if (!order.email) {
      dispatch({
        type: UPDATE_IS_VALID,
        isValid: false,
        validMsg: 'email is required',
      })
      return
    }
    dispatch({ type: SAVE_ORDER })

    orderService.save(order)
      .then(() => dispatch({
        type: SAVE_ORDER_SUCCESS,
      }))
      .catch(err => dispatch({
        type: SAVE_ORDER_FAILURE,
        payload: err,
      }))
  }
}

export function hideOrderError() {
  return {
    type: HIDE_ORDER_ERROR,
  }
}
export function hideOrderSuccess() {
  return {
    type: HIDE_ORDER_SUCCESS,
  }
}
export function hideIsValid() {
  return {
    type: HIDE_IS_VALID,
  }
}

export function updateOrderField(name, value) {
  return {
    type: UPDATE_ORDER_FIELD,
    name,
    value,
  }
}

export function resetOrderFields() {
  return {
    type: RESET_ORDER_FIELDS,
  }
}

const INITIAL_STATE = {
  order: {
    productName: '',
    weight: '',
    fullName: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  },
  isSaving: false,

  errorText: '',
  isError: false,
  isErrorShow: false,

  isSuccess: false,
  isSuccessShow: false,

  isValid: true,
  validMsg: '',
  isValidShow: false,
}

const ACTION_HANDLERS = {
  [SAVE_ORDER]: state => ({
    ...state,
    isSaving: true,
  }),
  [SAVE_ORDER_SUCCESS]: () => ({
    ...INITIAL_STATE,
    isSuccess: true,
    isSuccessShow: true,
  }),
  [SAVE_ORDER_FAILURE]: (state, action) => ({
    ...state,
    isSaving: false,
    errorText: action.payload,
    isError: true,
    isErrorShow: true,
  }),
  [HIDE_ORDER_ERROR]: state => ({
    ...state,
    isErrorShow: false,
  }),
  [HIDE_ORDER_SUCCESS]: state => ({
    ...state,
    isSuccessShow: false,
  }),
  [UPDATE_ORDER_FIELD]: (state, action) => ({
    ...state,
    order: {
      ...state.order,
      [action.name]: action.value,
    },
  }),
  [RESET_ORDER_FIELDS]: () => (INITIAL_STATE),
  [HIDE_IS_VALID]: state => ({
    ...state,
    isValidShow: false,
  }),
  [UPDATE_IS_VALID]: (state, action) => ({
    ...state,
    isValid: action.isValid,
    validMsg: action.validMsg,
    isValidShow: true,
  }),
}

export default function orderReducer(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
