export const sessionData = {
  get(key) {
    return JSON.parse(sessionStorage.getItem(key))
  },
  save(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data))
  },
  remove(key) {
    sessionStorage.removeItem(key)
  },
}

export const localData = {
  get(key) {
    return JSON.parse(localStorage.getItem(key))
  },
  save(key, data) {
    localStorage.setItem(key, JSON.stringify(data))
  },
  remove(key) {
    localStorage.removeItem(key)
  },
}
