/**
 * @fileOverview NotFound component
 */

import React from 'react'
import Brand from '../Brand'
import styles from './Footer.module.scss'

import * as service from '../../../services/page'
const INFO_PAGE_ID = 23

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      info: `

              <div class="title label">Address:</div>
              <div class="line">Chemgood</div>
              <div class="line">4908 Dominion BLVD, Suite F</div>
              <div class="line">Glen Allen, VA 23060 </div>
              <div class="line">USA</div>
              <div class="line">
                <span class="label">Phone# (Toll-free): </span>
                <a href="javascript:;">1-855-444-9966</a>
              </div>

              <div class="line">
                <span class="label">Fax#: </span>
                <a href="mailto:info@chemgood.com">1-866-899-6868</a>
              </div>
              <div class="line">
                <span class="label">Email: </span>
                <a href="mailto:info@chemgood.com">info@chemgood.com</a>
              </div>
              <div class="line">
                <span class="label">Website:</span>
                <a href="http://chemgood.com">chemgood.com</a>.
              </div>
      `
    }
  }
  componentDidMount() {
    service.fetchPage(INFO_PAGE_ID)
      .then(data => this.setState({
        info: data.data[0].text,
      }))
  }

  render() {
    return (
      <div className={styles.root}>
        <div className='detailContainer'>

          <div className='detail'>

            <div className='address' dangerouslySetInnerHTML={{ __html: this.state.info }}>
            </div>

            <div className='brandContainer'>
              <Brand vertical />
            </div>

          </div>


        </div>
        <div className='copyRight'>Copyright © 2017 Chemgood All rights reserved.</div>
      </div>
    )
  }
}

export default Footer
