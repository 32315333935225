import React, { Component } from 'react'

import * as pageService from '../../../services/page'

import s from './Contact.module.scss'

const CONTACT_PAGE_ID = 15

class Contact extends Component {

  constructor(props) {
    super(props)
    this.state = {
      page: {
        info: `

    <article>
      <section>
        <div>Company Name:</div>
        <p>Chemgood</p>
      </section>
      <section>
        <div>Address of Headquarter:</div>
        <p>4908 Dominion BLVD, Suite F</p>
        <p>Glen Allen, VA 23060</p>
        <p>USA</p>
      </section>
      <section>
        <div>Email:</div>
        <p><a href="mailto:info@chemgood.com">info@chemgood.com</a></p>
      </section>
      <section>
        <div>Website:</div>
        <p><a href="http://chemgood.com">chemgood.com</a></p>
      </section>
    </article>.
        `,
      },
    }
  }

  componentDidMount() {
    pageService.fetchPage(CONTACT_PAGE_ID)
      .then(data => this.setState({
        info: data.data[0].text,
      }))
  }

  render() {
    return (
      <div
        className={s.root}
        dangerouslySetInnerHTML={{ __html: this.state.info }}
      />
    )
  }
}

export default Contact
