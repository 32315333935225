import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import * as productService from '../../services/product'
import SearchBox from '../../core/components/SearchBox'

import CoreInfo from './CoreInfo'
import TechInfo from './TechInfo'

import Prices from './Prices'

import styles from './index.module.scss'


const INITIAL_STATE = {
  productDO: {
    productName: null,
    catalogId: null,
    cas: null,
    synonym: null,
    target: null,
  },
  productDetailDO: {
    additionalInformation: null,
    appearance: null,
    chemicalName: null,
    chemicalStructure: null,
    coa: null,
    msds: null,
    description: null,
    comments: null,
    formula: null,
    purity: null,
    reference: null,
    smilesCode: null,
    solubility: null,
    storageConditions: null,
    molecularWeight: null,
  },
  priceDOs: [],
  showError: false,
  error: true,
  errorText: null,
  saveProductSuccessOpen: false,
  loading: false,
  saving: false,
  shouldRedirect: false,
}

class Product extends React.Component {

  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
    window.scroll(0, 0)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      productService.fetchProduct(id)
        .then((data) => {
          const state = Object.assign(
            {},
            this.state,
            data,
          )
          this.setState(state)
        })
    }
  }

  render() {
    const { productDO, productDetailDO, priceDOs } = this.state

    if (!this.props.match.params.id) {
      return <Redirect to="/" />
    }
    return (
      <div className={styles.root}>
        <Helmet>
          <title>{`${productDO.productName} - Chemgood`}</title>
          <meta name="description" content={productDetailDO.description} />
        </Helmet>
        <div className={styles.searchContainer}>
          <SearchBox />
        </div>

        <div className={styles.section}>
          <div className={`${styles.leftPanel} ${styles.panel}`}>
            <CoreInfo {...this.state} />
          </div>

          <div className={`${styles.rightPanel} ${styles.panel}`}>
            <Prices prices={priceDOs} productName={productDO.productName} />

            <div className={styles.descriptionContainer}>
              <h2 className={styles.descriptionTitle}>Comments</h2>
              <p className={styles.description} style={{ fontWeight: 'bold' }}>{productDetailDO.comments}</p>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={`${styles.leftPanel} ${styles.panel}`}>
            <TechInfo {...this.state} />
          </div>

          <div className={`${styles.rightPanel} ${styles.panel}`}>
            <div>
              <h2 className={styles.descriptionTitle}>Description</h2>
              <p className={styles.description}>{productDetailDO.description}</p>
            </div>
          </div>
        </div>

        <div className={styles.section}>

          <div className={styles.descriptionContainer}>
            <h2 className={styles.descriptionTitle}>References</h2>
            <p className={styles.description}>{productDetailDO.reference}</p>
          </div>

        </div>
      </div>
    )
  }
}

export default Product
