import React from 'react'
import PropTypes from 'prop-types'

import s from './CoreInfo.module.scss'

const CoreInfo = ({ productDO, productDetailDO }) => (
  <div className={s.root}>
    <h2 className={s.title}>Products</h2>
    <div className={s.line}>
      Product Name:
      <div className={s.name}>
        {productDO.productName}
      </div>
    </div>
    <div className={s.line}>Catalog#: <span>C-{productDO.catalogId}</span> </div>
    {productDetailDO.chemicalStructure ? (
      <div className={s.line}>Chemical Structure:
        <div>
          <img
            src={productDetailDO.chemicalStructure}
            alt="Chemical Structure"
            className={s.chemicalStructure}
          />
        </div>
      </div>) : null
    }
    <div className={s.line}>Targets: <span>{productDO.target}</span> </div>
    <div className={s.line}>Synonym: <span>{productDO.synonym}</span> </div>

    <div className={s.line}>
      Chemical Name/IUPAC:
      <span>{productDetailDO.chemicalName}</span>
    </div>
    <div className={s.line}>
      SMILES Code: <span>{productDetailDO.smilesCode}</span>
    </div>
  </div>
)

CoreInfo.propTypes = {
  productDO: PropTypes.shape({}).isRequired,
  productDetailDO: PropTypes.shape({}).isRequired,
}

export default CoreInfo
