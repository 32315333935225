/**
 * @fileOverview NotFound component
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import s from './SearchBox.module.scss'

const placeholder = 'Name, CAS#, Catalog#, Target'

class SearchBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword || '',
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      keyword: nextProps.keyword,
    })
  }

  handleChange = e => this.setState({
    keyword: e.target.value,
  })


  handleSubmit = (e) => {
    e.preventDefault()

    const { onSearch, navigate } = this.props
    const { keyword } = this.state


    if (onSearch) {
      onSearch({ keyword })
    } else {
      navigate(`/products/${keyword}`)
    }
  }

  handleFocus = e => (e.target.placeholder = '')

  handleBlur = e => (e.target.placeholder = placeholder)

  render() {
    const { keyword } = this.state

    return (
      <form className={s.root} onSubmit={this.handleSubmit}>
        <div className={s.field}>
          <input
            className="form-control"
            onChange={this.handleChange}
            value={keyword}
            placeholder={placeholder}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </div>
        <button className="btn btn-primary">Search</button>
      </form>
    )
  }
}

SearchBox.defaultProps = {
  keyword: '',
  onSearch: null,
}
SearchBox.propTypes = {
  navigate: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  keyword: PropTypes.string,
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  navigate: url => dispatch(push(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
