import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import * as productService from '../../../services/product'
import s from './index.module.scss'

const defaultItem = 20

class Targets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      targets: [],
      allTargets: [],
      showAll: false,
    }
  }
  componentDidMount() {
    productService.fetchTargets()
      .then((allTargets) => {
        allTargets.sort((a, b) => a.toUpperCase().trim().localeCompare(b.toUpperCase().trim()))
        this.setState({
          allTargets,
          targets: allTargets.slice(0, defaultItem),
        })
      })
  }

  handleShowAll = () => {
    this.setState({
      targets: this.state.allTargets,
      showAll: true,
    })
  }


  render() {
    const { targets, showAll } = this.state
    return (
      <div className={s.root}>
        <div className={s.title}>Search By Targets</div>
        <div className={s.targets}>
          {targets.map(item => (
            <Link
              to={`/products/${item}`}
              key={item}
              className={s.target}
            >{item}</Link>
          ))}
        </div>
        {showAll ?
          null :
          <button
            onClick={this.handleShowAll}
            className="btn-brand"
            style={{
              backgroundColor: '#0275d8',
            }}
          >
            View More
          </button>
        }
      </div>
    )
  }
}

export default Targets
