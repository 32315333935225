import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import configureStore from './configureStore'
import history from './history'

// private routers
// import PrivateRoute from '../core/containers/PrivateRouteContainer'

// scenes
import Home from '../scenes/Home'
import Service from '../scenes/Service'
import Contact from '../scenes/Contact'
import Product from '../scenes/Product'
import ProductList from '../scenes/ProductList'

// containers/component
import Header from '../core/containers/HeaderContainer'
import NotFound from '../core/components/NotFound'
import Footer from '../core/components/Footer'

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = configureStore(initialState)

const App = () => (
  <Provider store={store}>
    { /* ConnectedRouter will use the store from Provider automatically */ }
    <ConnectedRouter history={history}>
      <div >
        <Helmet>
          <meta name="description" content="Chemgood is dedicated to providing high quality small molecule anticancer agents for laboratory research at competitive prices. Chemgood has an outstanding team with brilliant chemists and pharmacologists, and a well-equipped lab located here in the USA. Chemgood possesses a tremendous catalog of synthesized anticancer agents and also offers custom synthesis in a cost and time-efficient manner. Welcome to collaborate with Chemgood." />
          <meta name="keywords" content="Kinase inhibitor, Chemical, Cancer, Pharmacology, Biology, Biochemistry" />
        </Helmet>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact/:productName?" component={Contact} />
          <Route path="/service" component={Service} />
          <Route path="/products/:keyword?" component={ProductList} />
          <Route path="/product/:id?" component={Product} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </ConnectedRouter>
  </Provider>
)

export default App
