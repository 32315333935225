import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import s from './index.module.scss'
import logo from './logo.png'


const Brand = ({ vertical }) => (
  <Link to="/" className={`${s.homeLink} ${vertical ? s.vertical : ''}`}>
    <img src={logo} className={s.logo} alt="logo" />
    <span className={s.brandDetail}>
      <span className={s.brandName}>
        <span>CHEM</span>
        <span>GOOD</span>
      </span>
      <span className={s.brandIntro}>Professional Chemical Manufacturer</span>
    </span>
  </Link>
)

Brand.defaultProps = {
  vertical: false,
}
Brand.propTypes = {
  vertical: PropTypes.bool,
}

export default Brand
