import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'rc-pagination'
import Select from 'rc-select'

import s from './ProductsPagination.module.scss'


const enUS = {
  // Options.jsx
  items_per_page: '/ page',
  jump_to: 'Goto',
  page: '',

  // Pagination.jsx
  prev_page: 'Previous Page',
  next_page: 'Next Page',
  prev_5: 'Previous 5 Pages',
  next_5: 'Next 5 Pages',
  prev_3: 'Previous 3 Pages',
  next_3: 'Next 3 Pages',
}

const ProductsPagination = ({
  handlePaginationChange,
  totalItem,
  perPageSize,
  toPage,
}) => (
  <div className={s.root}>
    <Pagination
      onShowSizeChange={handlePaginationChange}
      onChange={handlePaginationChange}

      total={totalItem}
      defaultPageSize={perPageSize}
      current={toPage}

      locale={enUS}
      selectComponentClass={Select}
      showQuickJumper
      showSizeChanger
      pageSizeOptions={['50', '100', '200', '500']}
      showLessItems
    />
  </div>
)

ProductsPagination.propTypes = {
  handlePaginationChange: PropTypes.func.isRequired,
  totalItem: PropTypes.number.isRequired,
  perPageSize: PropTypes.number.isRequired,
  toPage: PropTypes.number.isRequired,
}

export default ProductsPagination
