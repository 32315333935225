import React from 'react'

import OrderForm from './containers/OrderForm'
import Contact from './components/Contact'

import SearchBox from '../../core/components/SearchBox'

import style from './index.module.scss'

const ContactView = props => (
  <div className={style.root}>
    <div className={style.searchContainer}>
      <SearchBox />
    </div>
    <div className={style.content}>
      <Contact />
    </div>
  </div>
)

export default ContactView
