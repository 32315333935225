import { connect } from 'react-redux'
import OrderForm from '../components/OrderForm'


import {
  updateOrderField,
  saveOrder,
  hideOrderError,
  hideOrderSuccess,
  hideIsValid,
  resetOrderFields,
} from '../../../core/modules/order'


const mapStateToProps = state => ({
  ...state.order,
})

const mapDispatchToProps = {
  updateOrderField,
  saveOrder,
  hideOrderError,
  hideOrderSuccess,
  hideIsValid,
  resetOrderFields,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm)
